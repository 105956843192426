<script setup>
useHead({
    bodyAttrs: {
        class: 'font-sans antialiased bg-slate-100 text-slate-800',
    },
});
</script>

<template>
    <main>
        <NuxtPage />
    </main>
</template>
